export function getAlbumOrder(albums: string[], isMobile: boolean) {
  if (isMobile) {
    const halfSize = Math.floor(albums.length / 2)
    let displayOrder = []

    // believe it or not...
    // this was easier than figuring out the grid css
    for (let i = 0; i < halfSize; i++) {
      displayOrder.push(albums[i])
      if (i + halfSize < albums.length) {
        displayOrder.push(albums[i + halfSize])
      }
    }
    if (albums.length % 2 === 1) {
      displayOrder.push(albums[albums.length - 1])
    }
    return displayOrder
  } else {
    return albums
  }
}
