import * as React from "react";
import { Checkbox } from "rsuite"

import { AlbumID } from "../Catalog/types";
import { StyledAlbumSetting } from "./StyledAlbumSetting";

export function AlbumSettingContainer ({
    albumID,
    title,
    albumCheckedState,
    handleOnChange,
    displayTitle,
    disabled,
}: {
    albumID: AlbumID,
    title: string
    albumCheckedState: boolean
    handleOnChange: (a: AlbumID) => void
    displayTitle: string | null
    disabled: boolean
}) {
    return (
        <StyledAlbumSetting albumID={albumID} disabled={disabled}>
            <Checkbox
                name={albumID}
                value={albumID}
                checked={albumCheckedState}
                onChange={() => handleOnChange(albumID)}
            >
                &nbsp;{displayTitle || title}
            </Checkbox>
        </StyledAlbumSetting>
    )
}

