import styled from 'styled-components';
import { useEffect, useState } from 'react';

import { lyricContainerPixels, lyricContainerMobile } from '../Style';

export enum Status {
    unGuessed = 0,
    guessed = 1,
    gaveUp = 2,
}

const StyledWordOuterContainer = styled.div<{
    status: Status
    isMobile: boolean
}>`
    border-bottom: 1px solid #d8d8d8;
    background-color: #fafafa;

    height: 15px;
    text-align: center;
    font-size: 10pt;

    ${({ status }) => {
        switch (status) {
            case Status.gaveUp: {
                return `color: blue;`
            } case Status.guessed: {
                return `color: black;`
            } default: {
                return `color: red;`
            }
        }
    }}


    ${({ isMobile }) => {
        if (isMobile) {
            return `width: ${lyricContainerMobile}px;`
        } else {
            return `width: ${lyricContainerPixels}px;`
        }
    }}

`

const StyledInnerContainer = styled.div``


export function Word ({
    status,
    word,
    isMobile,
    markWordAsSeen,
}: {
    status: Status,
    word: string
    isMobile: boolean,
    markWordAsSeen: (word: string) => void
}) {

    const [fadeClass, setFadeClass] = useState('fade-out-lyric');

    const displayWord = [Status.guessed, Status.gaveUp].includes(status)

    const onClick = () => {
        markWordAsSeen(word)
    }

    useEffect(() => {
        if (!displayWord) {
            setFadeClass('fade-out-lyric')
        } else {
            setFadeClass('fade-in-lyric')
        }
    }, [displayWord])

    return <StyledWordOuterContainer status={status} isMobile={isMobile} onClick={onClick}>
        <StyledInnerContainer
            className={fadeClass}
        >
            {displayWord ? word : ''}
        </StyledInnerContainer>
    </StyledWordOuterContainer>
}
