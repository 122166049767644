export const surpriseColors = {
  debut: {
    backgroundColor: '#92C992',
    inErasSetList: '#6A956A',
    playedLastYear: '#D6EBD6',
    playedThisYear: '#438943',
  },
  fearless: {
    backgroundColor: '#FFD599',
    inErasSetList: '#DBB77B',
    playedLastYear: '#FFEFD6',
    playedThisYear: '#FFA10A',
  },
  speak_now: {
    backgroundColor: '#CDB4DB',
    inErasSetList: '#A070C8',
    playedLastYear: '#E7E1F4',
    playedThisYear: '#6946AF',
  },
  red: {
    backgroundColor: '#F37C7C',
    inErasSetList: '#A75660',
    playedLastYear: '#F1D4DB',
    playedThisYear: '#A10222',
  },
  nineteen89: {
    backgroundColor: '#AFD2E9',
    inErasSetList: '#86AFCC',
    playedLastYear: '#EFF6FB',
    playedThisYear: '#3588C0',
  },
  reputation: {
    backgroundColor: '#60636C',
    inErasSetList: '#43454C',
    playedLastYear: '#DFDFE2',
    playedThisYear: '#000000',
  },
  lover: {
    backgroundColor: '#FFC8DD',
    inErasSetList: '#CD8EA7',
    playedLastYear: '#FFEBF2',
    playedThisYear: '#EC3CC0',
  },
  folklore: {
    backgroundColor: '#cfcec8',
    inErasSetList: '#b7b6b3',
    playedLastYear: '#EAEAE9',
    playedThisYear: '#797974',
  },
  evermore: {
    backgroundColor: '#E2C2A2',
    inErasSetList: '#CEA57B',
    playedLastYear: '#F6ECE3',
    playedThisYear: '#C48545',
  },
  midnights: {
    backgroundColor: '#456990',
    inErasSetList: '#404874',
    playedLastYear: '#C5CBDB',
    playedThisYear: '#1D2F6F',
  },
  ttpd: {
    backgroundColor: '#CFCDC4',
    inErasSetList: '#a09d92',
    playedLastYear: '#958d81',
    playedThisYear: '#958d81',
  },
  misc_songs: {
    backgroundColor: '#A3B8A7',
    inErasSetList: '#828D82',
    playedLastYear: '#DDE4DE',
    playedThisYear: '#59735E',
  },
}

export function getSurpriseColor(albumID: string, objectType: string) {
  let album = null

  switch (albumID) {
    case 'debut':
      album = surpriseColors.debut
      break
    case 'fearless':
      album = surpriseColors.fearless
      break
    case 'speak_now':
      album = surpriseColors.speak_now
      break
    case 'red':
      album = surpriseColors.red
      break
    case 'nineteen89':
      album = surpriseColors.nineteen89
      break
    case 'reputation':
      album = surpriseColors.reputation
      break
    case 'lover':
      album = surpriseColors.lover
      break
    case 'folklore':
      album = surpriseColors.folklore
      break
    case 'evermore':
      album = surpriseColors.evermore
      break
    case 'midnights':
      album = surpriseColors.midnights
      break
    case 'ttpd':
      album = surpriseColors.ttpd
      break
    case 'misc_songs':
      album = surpriseColors.misc_songs
      break
  }

  switch (objectType) {
    case 'erasSetlist':
      return album.inErasSetList
    case 'playedThisYear':
      return album.playedThisYear
    case 'playedLastYear':
      return album.playedLastYear
    case 'backgroundColor':
    default:
      return album.backgroundColor
  }
}
