import styled from 'styled-components';

import { AlbumID } from "../Catalog/types";
import { colors, fonts } from '../Style';

export const StyledAlbumSetting = styled.div<{
    albumID: AlbumID,
    disabled: boolean
}>`
  width: 180px;
  font-size: 20px;

  margin: 2px;
  padding: 1px;

  ${({ disabled }) => {
        if (disabled) {
            return `
        filter: brightness(75%);
        text-decoration: line-through;
        text-decoration-thickness: 0.5px;
        `
        }
    }}
  
  ${({ albumID }) => {
        switch (albumID) {
            case 'debut': {
                return `
              font-family: ${fonts.debut}, ${fonts.sans_serif};
              font-size: 22px;
              color: ${colors.debut};
              `
            } case 'fearless': {
                return `
              color: ${colors.fearless};
              font-family: ${fonts.fearless}, ${fonts.sans_serif};
              `
            } case 'red': {
                return `
              color: ${colors.red};
              font-family: ${fonts.red}, ${fonts.sans_serif};
              `
            } case 'speak_now': {
                return `
              font-family: ${fonts.speak_now}, ${fonts.sans_serif};
              font-size: 24px; 
              font-style: bold;
              color: ${colors.speak_now};
              `
            } case 'nineteen89': {
                return `
              font-family: ${fonts.nineteen89}, ${fonts.nineteen89_backup};
              color: ${colors.nineteen89};
              `
            } case 'reputation': {
                return `
              font-family: ${fonts.reputation}, ${fonts.sans_serif};
              color: ${colors.reputation};
              `
            } case 'lover': {
                return `
            font-family: ${fonts.lover}, ${fonts.sans_serif};
              font-size: 22px;
              color: ${colors.lover};
              `
            } case 'folklore': {
                return `
              font-family: ${fonts.folklore}, ${fonts.sans_serif};
              color: ${colors.folklore};
              `
            } case 'evermore': {
                return `
              font-family: ${fonts.evermore}, ${fonts.sans_serif};
              color: ${colors.evermore};
              `
            } case 'midnights': {
                return `
              font-family: ${fonts.midnights}, ${fonts.sans_serif};
              color: ${colors.midnights};
              `
            } case 'ttpd': {
                return `
                color: ${colors.ttpd};
            `
            } case 'eras_tour': {
                return `
                font-size: 18px;
                font-family: ${fonts.eras_tour};
                color: ${colors.eras_tour};
            `
            } default: {
                return `
              font-family: ${fonts.serif};
              color: ${colors.black};
              `
            }
        }
    }}
`