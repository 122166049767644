

import { allAlbums, entireCatalog } from '../../Catalog/catalog';
import { AlbumID } from '../../Catalog/types';
import { sanitizeInput } from '../../Util/utils';

type SwearWordCounter = {
  songCount: number
  whore: number
  damn: number
  goddamn: number
  hell: number
  fuck: number
  bitch: number
  shit: number
  dickhead: number
}

const initialSwearWordCounter = {
  songCount: 0,
  whore: 0,
  damn: 0,
  goddamn: 0,
  hell: 0,
  fuck: 0,
  bitch: 0,
  shit: 0,
  dickhead: 0,
}


function NormailzedGraph() {

  let results = {} as Record<AlbumID, SwearWordCounter>

  const swearWords = ['whore', 'damn', 'goddamn', 'hell', 'bitch', 'shit', 'fuck', 'dickhead']

  Object.values(entireCatalog).forEach(s => {
    const aId = s.album

    if (!results[aId]) {
      results[aId] = {...initialSwearWordCounter}
    }
    results[aId].songCount += 1

    s.lyrics.split(" ").forEach(w => {
      const normalizedWord = sanitizeInput(w)

      if (swearWords.includes(normalizedWord)) {
        // @ts-ignore
        results[aId][normalizedWord] += 1
      }
    })
  })

  const headers = ['albumId', ...swearWords]

  
  return <>
    <h1>Graphs</h1>

    <img src="/raw-counts.png" width={1000}/>
    <img src="/normalized.png" width={1000}/>
    <img src="/normalized-3d.png" width={1000}/>

    <br/>
    <h1>Raw data</h1>
    {
      headers.map(w => <>{w},{' '}</>)
    }
    {
      allAlbums.map(aID => (<div>
        {aID},{' '}
        { swearWords.map(s => {
          // @ts-ignore
          const val = results[aID][s]
          return <>{val},{' '}</>
        })}
        {/* {  Object.entries(results[aID]).map( ([key, value]) => <>{key} {value},{' '}</>)} */}
      </div>
    ))
    }
  </>
} export default NormailzedGraph;