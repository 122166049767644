import React from 'react';
import { useState } from 'react';

import styled from 'styled-components';
import { Footer } from '../../Footer';
import { LINKEDIN_LINK, PageDestination, TWITTER_LINK } from '../../Footer/data';
import SEO from '../../SEO';
import { SEOData } from '../../SEO/data';

const StyledMainContainer = styled.div`
  margin: 0 auto;
  padding: 4rem;
  width: 48rem;
`

const StyledAccordianContainer = styled.div`
  max-width: min(80%, 1000px);

  button {
    position: relative;
    display: block;
    text-align: left;
    width: 100%;
    padding: 1em 0;
    color: $text;
    font-size: 1.15rem;
    font-weight: 400;
    border: none;
    background: none;
    outline: none;
    &:hover, &:focus {
      cursor: pointer;
      color: #03b5d2;
      &::after {
        cursor: pointer;
        color: #03b5d2;
        border: 1px solid #03b5d2;
      }
    }
  }
  button[aria-expanded='true'] {
    color: #03b5d2;
    .icon {
      &::after {
        width: 0;
      }
    }
    + .accordion-content {
      opacity: 1;
      max-height: 9em;
      transition: all 200ms linear;
      will-change: opacity, max-height;
    }
  }
`

const AccordianTitle = styled.span`
  padding: 1em 1.5em 1em 0;
`

const AccordianIcon = styled.span`
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 0;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 22px;
  &::before {
    display: block;
    position: absolute;
    content: '';
    top: 9px;
    left: 5px;
    width: 10px;
    height: 2px;
    background: currentColor;
  }
  &::after {
    display: block;
    position: absolute;
    content: '';
    top: 5px;
    left: 9px;
    width: 2px;
    height: 10px;
    background: currentColor;
  }
`

const StyledAccordianItem = styled.div`
  border-bottom: 1px solid #e5e5e5;
  button[aria-expanded='true'] {
    border-bottom: 1px solid #03b5d2;
  }
`
const StyledAccordianContent = styled.div<{
  isToggled: boolean
}>`
  ${({ isToggled }) => {
    if (isToggled) {
      return `
        opacity: 1;
        max-height: 9em;
        transition: all 200ms linear;
        will-change: opacity, max-height;
        
      `
    } else {
      return `
        opacity: 0;
        max-height: 0;
        transition: opacity 200ms linear, max-height 200ms linear;
        will-change: opacity, max-height;
        overflow: hidden;
      `
    }
  }}
  
  p {
    font-size: 1rem;
    font-weight: 300;
    margin: 2em 0;
  }
`

const FooterBox = styled.div`
  margin-top: 25px;
  // Should this be part of the footer object? Probably but it's fine for now
  margin-right: -15px;
`

function FAQPage () {
  const faqIDs = [
    'mobile_sucks',
    'feedback',
    'design',
    'history_page',
    'timer',
    'lyricChanges',
    'mobile_word_bug',
    'if_this_was_a_movie',
    'misc_songs',
  ]

  const initialState = Object.fromEntries(faqIDs.map(x => [x, false]))
  const [buttonsChecked, setButtonsChecked] = useState(initialState);

  const toggleButton = function (buttonID: string) {
    let newState = {
      ...buttonsChecked
    }
    newState[buttonID] = !newState[buttonID]
    setButtonsChecked(newState)
  }

  return (
    <StyledMainContainer>
      <SEO seoObject={SEOData.faq} />

      <h2>Frequently Asked Questions</h2>

      <StyledAccordianContainer>

        <StyledAccordianItem onClick={() => toggleButton('eras-tour-broken')}>
          <button id={'eras-tour-broken'} aria-expanded={buttonsChecked['eras-tour-broken']} >
            <AccordianTitle>Why is the Eras Tour Thing Greyed out</AccordianTitle>
            <AccordianIcon aria-hidden={!buttonsChecked['eras-tour-broken']}></AccordianIcon>
          </button>
          <StyledAccordianContent isToggled={buttonsChecked['eras-tour-broken']}>
            <p>I added that a while ago and it must've broken without me noticing it. I'm working on fixing it soon!
            </p>
          </StyledAccordianContent>
        </StyledAccordianItem>

        <StyledAccordianItem onClick={() => toggleButton('timer')}>
          <button id={'timer'} aria-expanded={buttonsChecked['timer']} >
            <AccordianTitle>Do you ever plan on adding a timer?</AccordianTitle>
            <AccordianIcon aria-hidden={!buttonsChecked['timer']}></AccordianIcon>
          </button>
          <StyledAccordianContent isToggled={buttonsChecked['timer']}>
            <p>Yes! I want to add an optional timer and will get to that... "soon" :&#41;
            </p>
          </StyledAccordianContent>
        </StyledAccordianItem>

        <StyledAccordianItem onClick={() => toggleButton('history_page')}>
          <button id={'history_page'} aria-expanded={buttonsChecked['history_page']} >
            <AccordianTitle>What about adding a stats page?</AccordianTitle>
            <AccordianIcon aria-hidden={!buttonsChecked['history_page']}></AccordianIcon>
          </button>
          <StyledAccordianContent isToggled={buttonsChecked['history_page']}>
            <p>Yes! I <i>also</i> want to add a stats page and will also get to that... "soon" :&#41;
            </p>
          </StyledAccordianContent>
        </StyledAccordianItem>

        <StyledAccordianItem onClick={() => toggleButton('mobile_word_bug')}>
          <button id={'mobile_word_bug'} aria-expanded={buttonsChecked['mobile_word_bug']} >
            <AccordianTitle>What's up with the mobile site?</AccordianTitle>
            <AccordianIcon aria-hidden={!buttonsChecked['mobile_word_bug']}></AccordianIcon>
          </button>
          <StyledAccordianContent isToggled={buttonsChecked['mobile_word_bug']}>
            <p>Right now, this site really only works on Desktop. I plan to fix the mobile site... eventually but my current focus is on expanding the Desktop site.</p>
          </StyledAccordianContent>
        </StyledAccordianItem>

        <StyledAccordianItem onClick={() => toggleButton('mobile_sucks')}>
          <button id={'mobile_sucks'} aria-expanded={buttonsChecked['mobile_sucks']} >
            <AccordianTitle>On mobile, why do the words stick together on my keyboard?</AccordianTitle>
            <AccordianIcon aria-hidden={!buttonsChecked['mobile_sucks']}></AccordianIcon>
          </button>
          <StyledAccordianContent isToggled={buttonsChecked['mobile_sucks']}>
            <p>On mobile, sometimes when you get a word right and the guessed lyrics update, the previous word still appears in the word your phone thinks you're typing.
              Your phone might think you're typing the word "MyTearsRichochet".
            </p>
            <p>
              I know of that bug but haven't figured out how to fix yet. As a workaround, if you hit the "Enter" button, that will clear out the word on your keyboard.
            </p>
          </StyledAccordianContent>
        </StyledAccordianItem>

        <StyledAccordianItem onClick={() => toggleButton('feedback')}>
          <button id={'feedback'} aria-expanded={buttonsChecked['feedback']} >
            <AccordianTitle>I think you should add XYZ new feature!!</AccordianTitle>
            <AccordianIcon aria-hidden={!buttonsChecked['feedback']}></AccordianIcon>
          </button>
          <StyledAccordianContent isToggled={buttonsChecked['feedback']}>
            <p>
              I'm always happy to hear positive or constuctive feedback about this site to make it better! If you check out the&nbsp;<a href={PageDestination.changeLog}>change log</a> page, I might have already built your idea!
              <br />
              I work on this site on my freetime so I might not be able to build something quickly but I'm always thinking about ways to add to this site.
              You can reach out to me on
              &nbsp;<a target="_blank" href={TWITTER_LINK} rel="noreferrer">twitter</a>
              &nbsp;or&nbsp;<a target="_blank" href={LINKEDIN_LINK} rel="noreferrer">LinkedIn</a>.
            </p>
          </StyledAccordianContent>
        </StyledAccordianItem>


        <StyledAccordianItem onClick={() => toggleButton('lyricChanges')}>
          <button id={'lyricChanges'} aria-expanded={buttonsChecked['lyricChanges']} >
            <AccordianTitle>Are the lyrics accurate?</AccordianTitle>
            <AccordianIcon aria-hidden={!buttonsChecked['lyricChanges']}></AccordianIcon>
          </button>
          <StyledAccordianContent isToggled={buttonsChecked['lyricChanges']}>
            <p>
              I tried my best to get accurate lyrics but I might not have gotten everything 100 percent right.
              If you think the lyrics aren't accurate, correcting them is a pretty quick fix and you can reach out to me on
              &nbsp;<a target="_blank" href={TWITTER_LINK} rel="noreferrer">twitter</a>
              &nbsp;or&nbsp;<a target="_blank" href={LINKEDIN_LINK} rel="noreferrer">LinkedIn</a>.
            </p>
          </StyledAccordianContent>
        </StyledAccordianItem>

        <StyledAccordianItem onClick={() => toggleButton('if_this_was_a_movie')}>
          <button id={'if_this_was_a_movie'} aria-expanded={buttonsChecked['if_this_was_a_movie']} >
            <AccordianTitle>Why is "If This Was A Movie" on "Fearless" instead of "Speak Now"?</AccordianTitle>
            <AccordianIcon aria-hidden={!buttonsChecked['if_this_was_a_movie']}></AccordianIcon>
          </button>
          <StyledAccordianContent isToggled={buttonsChecked['if_this_was_a_movie']}>
            <p>
              With the re-recording project re-releasing previous standard album tracks, bonus tracks, movie features, and collaborations, onto one album it's been hard trying to figure out <i>where</i> to put certain songs.
              If I'm unsure about where to place a song, I use where the "Taylor's Version" of the track ends up to determine that.
              So, that means that "If This Was A Movie" is grouped in with the <a href="https://open.spotify.com/album/6IZm7NfvWyXp952VF36Z5F?si=JnwxtAJaQtausteVuGeyJw">"Fearless" album</a> even though it originally appeared on the <a href="https://open.spotify.com/track/0vvt4IZOMkRug195S4MUq0?si=254f94355b5b4635">deluxe edition of "Speak Now"</a>.

              <br /><br />If you think I've gotten a song placement wrong, reach out and let me know!
            </p>
          </StyledAccordianContent>
        </StyledAccordianItem>

        <StyledAccordianItem onClick={() => toggleButton('misc_songs')}>
          <button id={'misc_songs'} aria-expanded={buttonsChecked['misc_songs']} >
            <AccordianTitle>What songs are in the "Miscellaneous" Category?</AccordianTitle>
            <AccordianIcon aria-hidden={!buttonsChecked['misc_songs']}></AccordianIcon>
          </button>
          <StyledAccordianContent isToggled={buttonsChecked['misc_songs']}>
            <p>
              The miscellaneous songs are the new songs from the "Beautiful Eyes EP", Movie Features (like "Crazier", and "Eyes Open"), collaborations (like "The Alcott" and "Highway Don't Care"), and her <i>original</i> christmas songs ("Christmases When You Were Mine", "Christmas Must Be Something More", and "Christmas Tree Farm").
              <br />
              If you want to see a full list of the songs, check out the right side of the <a href={PageDestination.surpriseSongs}>surprise song tracker</a> which lists all of her released songs and collaborations. If you think I'm missing something, let me know and I'll add it!
            </p>
          </StyledAccordianContent>
        </StyledAccordianItem>

        <FooterBox>
          <Footer currentPage='faq' />
        </FooterBox>

      </StyledAccordianContainer>

    </StyledMainContainer>

  );

} export default FAQPage;