import { Catalog, ReleaseType } from './types'

export const debut: Catalog = {
  tim_mcgraw: {
    album: 'debut',
    spotifyLink: 'https://open.spotify.com/track/0Om9WAB5RS09L80DyOfTNa',
    id: 'tim_mcgraw',
    title: 'Tim McGraw',
    ogReleaseDate: '06-29-2006',
    releaseType: ReleaseType.mainAlbum,
    lyrics:
      "He said the way my blue eyes shined Put those Georgia stars to shame that night I said, \"That's a lie\" Just a boy in a Chevy truck That had a tendency of gettin' stuck On backroads at night And I was right there beside him all summer long And then the time we woke up to find that summer gone But when you think Tim McGraw I hope you think my favorite song The one we danced to all night long The moon like a spotlight on the lake When you think happiness I hope you think that little black dress Think of my head on your chest And my old faded blue jeans When you think Tim McGraw I hope you think of me September saw a month of tears And thanking God that you weren't here To see me like that But in a box beneath my bed Is a letter that you never read From three summers back It's hard not to find it all a little bittersweet And lookin' back on all of that, it's nice to believe When you think Tim McGraw I hope you think my favorite song The one we danced to all night long The moon like a spotlight on the lake When you think happiness I hope you think that little black dress Think of my head on your chest And my old faded blue jeans When you think Tim McGraw I hope you think of me And I'm back for the first time since then I'm standing on your street And there's a letter left on your doorstep And the first thing that you'll read Is when you think Tim McGraw I hope you think my favorite song Someday you'll turn your radio on I hope it takes you back to that place When you think happiness I hope you think that little black dress Think of my head on your chest And my old faded blue jeans When you think Tim McGraw I hope you think of me Oh, think of me Mmm He said the way my blue eyes shined Put those Georgia stars to shame that night I said, \"That's a lie\"",
  },
  picture_to_burn: {
    spotifyLink: 'https://open.spotify.com/track/32mVHdy0bi1XKgr0ajsBlG',
    album: 'debut',
    id: 'picture_to_burn',
    title: 'Picture to Burn',
    ogReleaseDate: '10-24-2006',
    releaseType: ReleaseType.mainAlbum,
    lyrics:
      "State the obvious, I didn't get my perfect fantasy I realize you love yourself more than you could ever love me So go and tell your friends that I'm obsessive and crazy That's fine, you won't mind if I say By the way I hate that stupid old pickup truck you never let me drive You're a redneck heartbreak who's really bad at lying So watch me strike a match on all my wasted time As far as I'm concerned, you're just another picture to burn There's no time for tears I'm just sitting here planning my revenge There's nothing stopping me From going out with all of your best friends And if you come around saying sorry to me My daddy's gonna show you how sorry you'll be 'Cause I hate that stupid old pickup truck you never let me drive You're a redneck heartbreak who's really bad at lying So watch me strike a match on all my wasted time As far as I'm concerned, you're just another picture to burn And if you're missing me, you'd better keep it to yourself 'Cause coming back around here would be bad for your health 'Cause I hate that stupid old pickup truck you never let me drive You're a redneck heartbreak who's really bad at lying So watch me strike a match on all my wasted time In case you haven't heard, I really, really hate that Stupid old pickup truck you never let me drive You're a redneck heartbreak who's really bad at lying So watch me strike a match on all my wasted time As far as I'm concerned, you're just another picture to burn Burn, burn, burn, baby, burn Just another picture to burn Baby, burn",
  },
  teardrops_on_my_guitar: {
    spotifyLink: 'https://open.spotify.com/track/4pJi1rVt9GNegU9kywjg4z',
    album: 'debut',
    ogReleaseDate: '10-24-2006',
    id: 'teardrops_on_my_guitar',
    title: 'Teardrops on My Guitar',
    releaseType: ReleaseType.mainAlbum,
    lyrics:
      "Drew looks at me, I fake a smile so he won't see That I want and I'm needing everything that we should be I'll bet she's beautiful, that girl he talks about And she's got everything that I have to live without Drew talks to me, I laugh 'cause it's just so funny But I can't even see anyone when he's with me He says he's so in love, he's finally got it right I wonder if he knows he's all I think about at night He's the reason for the teardrops on my guitar The only thing that keeps me wishing on a wishing star He's the song in the car I keep singing, don't know why I do Drew walks by me, can he tell that I can't breathe? And there he goes, so perfectly The kind of flawless I wish I could be She'd better hold him tight, give him all her love Look in those beautiful eyes and know she's lucky 'cause He's the reason for the teardrops on my guitar The only thing that keeps me wishing on a wishing star He's the song in the car I keep singing, don't know why I do So I drive home alone, as I turn out the light I'll put his picture down and maybe get some sleep tonight 'Cause he's the reason for the teardrops on my guitar The only one who's got enough of me to break my heart He's the song in the car I keep singing, don't know why I do He's the time taken up, but there's never enough And he's all that I need to fall into Drew looks at me, I fake a smile so he won't see",
  },
  a_place_in_this_world: {
    spotifyLink: 'https://open.spotify.com/track/73OX8GdpOeGzKC6OvGSbsv',
    album: 'debut',
    ogReleaseDate: '10-24-2006',
    id: 'a_place_in_this_world',
    title: 'A Place in This World',
    releaseType: ReleaseType.mainAlbum,
    lyrics:
      "I don't know what I want, so don't ask me 'Cause I'm still trying to figure it out Don't know what's down this road I'm just walking Trying to see through the rain coming down Even though I'm not the only one, who feels The way I do I'm alone, on my own And that's all I know, I'll be strong, I'll be wrong Oh, but life goes on Oh, I'm just a girl Trying to find a place in this world Got the radio on, my old blue jeans And I'm wearing my heart on my sleeve Feeling lucky today, got the sunshine Could you tell me what more do I need? And tomorrow's just a mystery, oh yeah But that's okay I'm alone, on my own And that's all I know, I'll be strong, I'll be wrong Oh, but life goes on Oh, I'm just a girl Trying to find a place in this world Maybe I'm just a girl on a mission But I'm ready to fly I'm alone, on my own And that's all I know Oh, I'll be strong, I'll be wrong Oh, but life goes on Oh, I'm alone, on my own And that's all I know Oh, I'm just a girl Trying to find a place in this world Oh, I'm just a girl Oh, I'm just a girl, oh, oh Oh, I'm just a girl",
  },
  cold_as_you: {
    spotifyLink: 'https://open.spotify.com/track/7an1exwMnfYRcdVQm0yDev',
    album: 'debut',
    id: 'cold_as_you',
    title: 'Cold as You',
    ogReleaseDate: '10-24-2006',
    releaseType: ReleaseType.mainAlbum,
    lyrics:
      "You have a way of coming easily to me And when you take, you take the very best of me So I start a fight 'cause I need to feel somethin' And you do what you want 'cause I'm not what you wanted Oh, what a shame What a rainy ending given to a perfect day Just walk away Ain't no use defending words that you will never say And now that I'm sittin' here thinkin' it through I've never been anywhere cold as you You put up the walls and paint them all a shade of gray And I stood there loving you, and wished them all away And you come away with a great little story Of a mess of a dreamer with the nerve to adore you Oh, what a shame What a rainy ending given to a perfect day Just walk away Ain't no use defending words that you will never say And now that I'm sittin' here thinkin' it through I've never been anywhere cold as you You never did give a damn thing, honey But I cried, cried for you And I know you wouldn't have told nobody if I died, died for you Died for you Oh, what a shame What a rainy ending given to a perfect day Oh, every smile you fake is so condescending Countin' all the scars you've made Now that I'm sittin' here thinkin' it through I've never been anywhere cold as you",
  },
  the_outside: {
    spotifyLink: 'https://open.spotify.com/track/2QA3IixpRcKyOdG7XDzRgv',
    album: 'debut',
    id: 'the_outside',
    ogReleaseDate: '10-24-2006',
    title: 'The Outside',
    releaseType: ReleaseType.mainAlbum,
    lyrics:
      "I didn't know what I would find When I went lookin' for a reason I know I didn't read between the lines And baby I've got nowhere to go I tried to take the road less traveled by But nothing seems to work the first few times, am I right? So, how can I ever try to be better? Nobody ever let's me in And I can still see you, this ain't the best view On the outside looking in I've been a lot of lonely places I've never been on the outside You saw me there but never knew That I would give it all up to be A part of this, a part of you And now it's all too late so you see You could have helped if you had wanted to But no one notices until it's too late to do anything How can I ever try to be better? Nobody ever let's me in And I can still see you, this ain't the best view On the outside looking in I've been a lot of lonely places I've never been on the outside Oh yeah How can I ever try to be better? Nobody ever let's me in And I can still see you, this ain't the best view On the outside looking in I've been a lot of lonely places I've never been on the outside Oh, oh, oh-oh Oh, oh-oh ",
  },
  tied_together_with_a_smile: {
    album: 'debut',
    spotifyLink: 'https://open.spotify.com/track/6K0CJLVXqbGMeJSmJ4ENKK',
    ogReleaseDate: '10-24-2006',
    id: 'tied_together_with_a_smile',
    title: 'Tied Together with a Smile',
    releaseType: ReleaseType.mainAlbum,
    lyrics:
      "Seems the only one who doesn't see your beauty Is the face in the mirror looking back at you You walk around here thinking you're not pretty But that's not true 'cause I know you Hold on, baby; you're losing it The water's high, you're jumping into it And letting go, and no one knows That you cry; but you don't tell anyone That you might not be the golden one And you're tied together with a smile But you're coming undone I guess it's true that love was all you wanted 'Cause you're givin' it away like it's extra change Hoping it will end up in his pocket But he leaves you out like a penny in the rain Oh, 'cause it's not his price to pay It's not his price to pay Hold on, baby; you're losing it The water's high, you're jumping into it And letting go, and no one knows That you cry; but you don't tell anyone That you might not be the golden one And you're tied together with a smile But you're coming undone Hold on, baby; you're losing it The water's high, you're jumping into it And letting go, and no one knows That you cry; but you don't tell anyone That you might not be the golden one And you're tied together with a smile But you're coming undone You're tied together with a smile But you're coming undone, oh Goodbye, baby With a smile, baby, baby",
  },
  stay_beautiful: {
    album: 'debut',
    spotifyLink: 'https://open.spotify.com/track/2ZoOmCSgj0ypVAmGd1ve4y',
    id: 'stay_beautiful',
    title: 'Stay Beautiful',
    ogReleaseDate: '10-24-2006',
    releaseType: ReleaseType.mainAlbum,
    lyrics:
      "Cory's eyes are like a jungle He smiles it's like the radio He whispers songs into my window In words that nobody knows There's pretty girls on every corner They watch him as he's walking home Saying, does he know Will you ever know? You're beautiful Every little piece, love don't you know? You're really gonna be someone Ask anyone And when you find everything you've looked for I hope your love leads you back to my door Oh, but if it don't, stay beautiful Cory finds another way to be The highlight of my day I'm taking pictures in my mind So I can save them for a rainy day It's hard to make a conversation When he's taking my breath away I should say \"hey, by the way\" You're beautiful Every little piece, love don't you know? You're really gonna be someone Ask anyone And when you find everything you've looked for I hope your love leads you back to my door Oh, but if it don't, stay beautiful If you and I are a story That never gets told If what you are is a daydream I'll never get to hold, at least you'll know You're beautiful Every little piece, love don't you know? You're really gonna be someone Ask anyone And when you find everything you've looked for I hope your love leads you back to my front door Oh, but if it don't Will you stay beautiful, beautiful, beautiful Beautiful, beautiful, beautiful? Oh, oh, oh Oh, oh, oh Oh, but if it don't Stay beautiful Stay beautiful Na, na, na, na, na",
  },
  shouldve_said_no: {
    album: 'debut',
    id: 'shouldve_said_no',
    releaseType: ReleaseType.mainAlbum,
    spotifyLink: 'https://open.spotify.com/track/16MVSD0mGI4RMQT83Qzm69',
    ogReleaseDate: '10-24-2006',
    title: "Should've Said No",
    lyrics:
      "It's strange to think the songs we used to sing The smiles, the flowers, everything is gone Yesterday I found out about you Even now just looking at you Feels wrong You say that you'd take it all back Given one chance, it was a moment of weakness And you said, \"Yes\" You should've said, \"No\" You should've gone home You should've thought twice 'fore you let it all go You should've known that word 'bout what you did with her Get back to me (get back to me) And I should've been there In the back of your mind I shouldn't be asking myself, \"Why?\" You shouldn't be beggin' for forgiveness at my feet You should've said, \"No\" Baby, and you might still have me You can see that I've been cryin' Baby, you know all the right things To say But do you honestly expect me to believe We could ever be the same? You say that the past is the past You need one chance, it was a moment of weakness And you said, \"Yes\" You should've said, \"No\" You should've gone home You should've thought twice 'fore you let it all go You should've known that word 'bout what you did with her Get back to me (get back to me) And I should've been there In the back of your mind I shouldn't be asking myself, \"Why?\" You shouldn't be beggin' for forgiveness at my feet You should've said, \"No\" Baby, and you might still have me, oh-whoa I can't resist Before you go, tell me this Was it worth it? Was she worth this? No, no, no, no, no, no You should've said, \"No\" You should've gone home You should've thought twice 'fore you let it all go You should've known that word 'bout what you did with her Get back to me (get back to me) And I should've been there In the back of your mind I shouldn't be asking myself, \"Why?\" You shouldn't be beggin' for forgiveness at my feet You should've said, \"No\" Baby, and you might still have me",
  },
  marys_song: {
    spotifyLink: 'https://open.spotify.com/track/2QrQCMel6v2JiLxqrg4p2O',
    album: 'debut',
    id: 'marys_song',
    ogReleaseDate: '10-24-2006',
    title: "Mary's Song (Oh My My My)",
    releaseType: ReleaseType.mainAlbum,
    lyrics:
      'She said, "I was seven and you were nine" I looked at you like the stars that shine In the sky, the pretty lights And our daddies used to joke about the two of us Growing up and falling in love And our mamas smiled and rolled their eyes And said, "Oh my, my, my" Take me back to the house in the backyard tree Said you\'d beat me up, you were bigger than me You never did, you never did Take me back when our world was one block wide I dared you to kiss me and ran when you tried Just two kids, you and I Oh my, my, my, my I was sixteen when suddenly I wasn\'t that little girl you used to see But your eyes still shined like pretty lights And our daddies used to joke about the two of us They never believed we\'d really fall in love And our mamas smiled and rolled their eyes And said, "Oh my, my, my" Take me back to the creek beds we turned up 2 a.m. riding in your truck and all I need is you next to me Take me back to the time we had our very first fight The slamming of doors instead of kissing goodnight You stayed outside till the morning light Oh my, my, my, my A few years had gone and come around We were sitting at our favorite spot in town And you looked at me, got down on one knee Take me back to the time when we walked down the aisle Our whole town came and our mamas cried You said, "I do", and I did too Take me home where we met so many years before We\'ll rock our babies on that very front porch After all this time, you and I And I\'ll be eighty-seven; you\'ll be eighty-nine I\'ll still look at you like the stars that shine In the sky, oh my, my, my',
  },
  our_song: {
    spotifyLink: 'https://open.spotify.com/track/15DeqWWQB4dcEWzJg15VrN',
    album: 'debut',
    id: 'our_song',
    ogReleaseDate: '10-24-2006',
    title: 'Our Song',
    releaseType: ReleaseType.mainAlbum,
    lyrics:
      "I was ridin' shotgun with my hair undone In the front seat of his car He's got a one-hand feel on the steering wheel The other on my heart I look around, turn the radio down He says, \"Baby, is something wrong?\" I say, \"Nothing, I was just thinkin' how we don't have a song\" And he says Our song is the slamming screen door Sneakin' out late, tapping on your window When we're on the phone, and you talk real slow 'Cause it's late, and your mama don't know Our song is the way you laugh The first date, \"Man, I didn't kiss her, and I should have\" And when I got home, 'fore I said, \"Amen\" Asking God if he could play it again I was walkin' up the front porch steps after everything that day Had gone all wrong and been trampled on And lost and thrown away Got to the hallway, well on my way to my lovin' bed I almost didn't notice all the roses And the note that said Our song is the slamming screen door Sneakin' out late, tapping on your window When we're on the phone, and you talk real slow 'Cause it's late, and your mama don't know Our song is the way you laugh The first date, \"Man, I didn't kiss her and I should have\" And when I got home, 'fore I said, \"Amen\" Asking God if he could play it again I've heard every album, listened to the radio Waited for something to come along That was as good as our song 'Cause our song is the slamming screen door Sneakin' out late, tapping on his window When we're on the phone, and he talks real slow 'Cause it's late, and his mama don't know Our song is the way he laughs The first date, \"Man, I didn't kiss him, and I should have\" And when I got home, 'fore I said, \"Amen\" Askin' God if he could play it again, play it again Oh, yeah Oh, oh yeah I was ridin' shotgun with my hair undone In the front seat of his car I grabbed a pen and an old napkin And I wrote down our song",
  },

  im_only_me_when_im_with_you: {
    spotifyLink: 'https://open.spotify.com/track/0JIdBrXGSJXS72zjF9ss9u',
    album: 'debut',
    id: 'im_only_me_when_im_with_you',
    ogReleaseDate: '07-15-2008',
    title: "I'm Only Me When I'm with You",
    releaseType: ReleaseType.mainAlbum,
    lyrics:
      "Friday night beneath the stars In a field behind your yard You and I are painting pictures in the sky Sometimes we don't say a thing Just listen to the crickets sing Everything I need is right here by my side And I know everything about you I don't wanna live without you I'm only up when you're not down Don't wanna fly if you're still on the ground It's like no matter what I do Well, you drive me crazy half the time The other half I'm only trying To let you know that what I feel is true And I'm only me when I'm with you Just a small-town boy and girl Living in the crazy world Trying to figure out what is and isn't true And I don't try to hide my tears My secrets or my deepest fears Through it all nobody gets me like you do And you know everything about me You say that you can't live without me I'm only up when you're not down Don't wanna fly if you're still on the ground It's like no matter what I do Well, you drive me crazy half the time The other half I'm only trying To let you know that what I feel is true And I'm only me when I'm with you When I'm with anybody else It's so hard to be myself And only you can tell I'm only up when you're not down Don't wanna fly if you're still on the ground It's like no matter what I do Well, you drive me crazy half the time The other half I'm only trying To let you know that what I feel is true And I'm only me when I'm with you",
  },
  invisible: {
    spotifyLink: 'https://open.spotify.com/track/5OOd01o2YS1QFwdpVLds3r',
    album: 'debut',
    id: 'invisible',
    ogReleaseDate: '10-24-2006',
    title: 'Invisible',
    releaseType: ReleaseType.mainAlbum,
    lyrics:
      "She can't see the way your eyes Light up when you smile She'll never notice how you stop and stare Whenever she walks by And you can't see me wantin' you the way you want her But you are everything to me And I just wanna show you She don't even know you She's never gonna love you like I want to And you just see right through me but if you only knew me We could be a beautiful, miracle, unbelievable Instead of just invisible There's a fire inside of you That can't help but shine through She's never gonna see the light No matter what you do And all I think about is how to make you think of me And everything that we could be And I just wanna show you She don't even know you She's never gonna love you like I want to And you just see right through me but if you only knew me We could be a beautiful, miracle, unbelievable Instead of just invisible Like shadows in a faded light Oh we're invisible I just wanna open your eyes And make you realize I just want to show you, she don't even know you, Baby let me love you, let me want you She can't see the way your eyes Light up when you smile",
  },
  a_perfectly_good_heart: {
    album: 'debut',
    spotifyLink: 'https://open.spotify.com/track/1spLfUJxtyVyiKKTegQ2r4',
    ogReleaseDate: '10-24-2006',
    id: 'a_perfectly_good_heart',
    title: 'A Perfectly Good Heart',
    releaseType: ReleaseType.mainAlbum,
    lyrics:
      "Why would you wanna break a perfectly good heart Why would you wanna take our love and tear it all apart now Why would you wanna make the very first scar Why would you wanna break a perfectly good heart Maybe I should've seen the signs, should've read the writing on the wall And realized by the distance in your eyes that I would be the one to fall No matter what you say I still can't believe That you would walk away It don't make sense to me but Why would you wanna break a perfectly good heart Why would you wanna take our love and tear it all apart now Why would you wanna make the very first scar Why would you wanna break a perfectly good heart It's not unbroken anymore How do I get it back the way it was before Why would you wanna break a perfectly good heart Why would you wanna take our love and tear it all apart now Why would you wanna make the very first scar Why would you wanna break, would you wanna break a Why would you wanna break a perfectly good heart Why would you wanna take our love and tear it all apart now Why would you wanna make the very first scar Why would you wanna break a perfectly good heart",
  },
}
