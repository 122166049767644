

function EmailCopy () {


  return <html lang="en">
    <head>
      <meta charSet="UTF-8/" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>On Campus Opportunity</title>
    </head>
    <body>
      <h1>Student Opportunity: Earn Up to $200</h1>

      <p>Hi there, I'm Sam with Turnout the Vote! I'm reaching out with a program for students to get involved with the election - <u><b>no experience required!</b></u></p>

      <p>We know that conversations with friends and family are the BEST
        way to have an impact on this election, and that's why
        at Turnout the Vote <b><u>we are compensating people in
          battleground states up to $200 to talk with their
          friends and family about voting - and we'd love your help promoting the opportunity to students!</u></b></p>

      <h2>How It Works</h2>
      <ul>
        <li><u><b>Attend</b></u> a one-hour Zoom Onboarding Session and a one-hour Zoom Get Out the Vote event. Sessions are flexible, running multiple times a day.</li>
        <li><u><b>Engage</b></u> in meaningful conversations with friends and family about the upcoming election and the importance of voting.</li>
        <li><u><b>Share</b></u> their experiences and details of the conversations they have to help us measure impact.</li>
        <li>Get paid $200!</li>
      </ul>

      <p style={{ marginBottom: '0' }}>With only a few days left until Election Day, it's more imporant to make your voices heard! </p>
      <h2 style={{ textAlign: 'center' }}>Apply <a href="https://turnoutarizona.com/oncampus">here</a> today!</h2>

      <p>-Sam from Turnout the Vote</p>

      <br />
      <p style={{ color: '#696969', fontSize: '12px', textAlign: 'center', marginTop: '0', marginBottom: '0' }}>2093 Philadelphia Pike 7887 Claymont, DE 19703</p>
      <p style={{ color: '#696969', fontSize: '12px', textAlign: 'center', marginTop: '0', marginBottom: '0' }}>Click <a href="https://share.hsforms.com/1Ak1Vm2-WTA2oIzAT8R0OJAejc6h">here</a> to unsubscribe</p>
      <p style={{ color: '#696969', fontSize: '12px', textAlign: 'center', marginTop: '0', marginBottom: '0' }}>Follow us on <a href="https://x.com/turnout_thevote">Twitter</a> or <a href="https://www.instagram.com/turnout_the_vote">instagram</a></p>
    </body>
  </html >

}
export default EmailCopy