import { PageTypeCase } from '../Footer/data'

export type SeoEntry = {
  title: string
  description: string
  keywords?: string
}

export const SEOData: { [K in PageTypeCase]: SeoEntry } = {
  faq: {
    title: 'TSSongGuesser - FAQ',
    keywords: 'FAQ, TSSongGuesser.com, Taylor Swift Song Guesser',
    description: 'The FAQ page for www.TSSongGuesser.com',
  },
  songGuesser: {
    title: 'Taylor Swift Song Guesser',
    keywords:
      'Taylor Swift Song guesser, Taylor Swift Lyric Guesser, Guess the taylor swift song, Guess the taylor swift lyrics, taylor swift song quiz, taylor swift lyric quiz, taylor swift puzzle, guess without hints, first line of taylor swift, taylor swift quiz, triva, quiz, song, taylor swift, song guesser',
    description:
      'Can you guess the Taylor Swift lyrics without any hints? Try it out on all of her songs and albums including Folklore, Midnights, and 1989',
  },
  surpriseSongs: {
    title: 'Surprise Song Tracker',
    keywords:
      'Surprise Song Tracker, Taylor Swift, Eras Tour Surprise Song, Surprise Song',
    description: "Taylor Swift Era's tour surprise song tracker.",
  },
  changeLog: {
    title: 'TSSongGuesser - Change Log',
    keywords:
      'Change Log, Updates, Taylor Swift Song Guesser, TSSongGuesser.com,',
    description: 'The updates page for www.TSSongGuesser.com',
  },
}
