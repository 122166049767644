import { debut } from './debut'
import { evermore } from './evermore'
import { fearless } from './fearless'
import { folklore } from './folklore'
import { midnights } from './midnights'
import { nineteen89 } from './nineteen89'
import { red } from './red'
import { lover } from './lover'
import { reputation } from './reputation'
import { speak_now } from './speak_now'
import { misc_songs } from './misc_songs'
import { unreleased } from './unreleased_songs'
import { ttpd } from './the_tortured_poets_department'

import { AlbumID, Albums, Catalog, SongID, AllAlbumType } from './types'

export const entireCatalog: Catalog = {
  ...debut,
  ...fearless,
  ...speak_now,
  ...red,
  ...reputation,
  ...nineteen89,
  ...lover,
  ...folklore,
  ...evermore,
  ...midnights,
  ...ttpd,
  ...misc_songs,
  ...unreleased,
}

export const defaultCheckedAlbums: AlbumID[] = ['ttpd']

export const allAlbums: AlbumID[] = [
  'debut',
  'fearless',
  'speak_now',
  'red',
  'reputation',
  'nineteen89',
  'lover',
  'folklore',
  'evermore',
  'midnights',
  'ttpd',
]

export const erasTourSetlist: SongID[] = [
  'miss_americana',
  'cruel_summer',
  'the_man',
  'you_need_to_calm_down',
  'lover_song',
  'the_archer',
  'fearless_song',
  'you_belong_with_me',
  'love_story',
  'tis_the_damn_season',
  'willow',
  'marjorie',
  'champagne_problems',
  'tolerate_it',
  'ready_for_it',
  'delicate',
  'dont_blame_me',
  'look_what_you_made_me_do',
  'enchanted',
  'long_live',
  'twenty_two',
  'i_knew_you_were_trouble',
  'all_too_well_ten',
  'we_are_never_ever_getting_back_together',
  'the_one',
  'invisible_string',
  'betty',
  'the_last_great_american_dynasty',
  'august',
  'illicit_affairs',
  'my_tears_ricochet',
  'cardigan',
  'style',
  'blank_space',
  'shake_it_off',
  'wildest_dreams',
  'bad_blood',
  'but_daddy_i_love_him',
  'so_high_school',
  'waolom',
  'down_bad',
  'fortnight',
  'the_smallest_man_who_ever_lived',
  'i_can_do_it_with_a_broken_heart',
  'lavender_haze',
  'anti_hero',
  'midnight_rain',
  'vigilante_shit',
  'bejeweled',
  'mastermind',
  'karma',
]

export const AllAlbums: AllAlbumType = {
  debut: debut,
  fearless: fearless,
  speak_now: speak_now,
  red: red,
  nineteen89: nineteen89,
  reputation: reputation,
  lover: lover,
  folklore: folklore,
  evermore: evermore,
  midnights: midnights,
  ttpd: ttpd,
  misc_songs: misc_songs,
  unreleased: unreleased,
}

export const AlbumOrderForSurprise: AlbumID[] = [
  'debut',
  'fearless',
  'speak_now',
  'red',
  'nineteen89',
  'reputation',
  'lover',
  'folklore',
  'evermore',
  'midnights',
  'ttpd',
  'misc_songs',
]

export const AlbumTitles: Albums = {
  debut: {
    title: 'Taylor Swift',
    albumID: 'debut',
  },
  fearless: {
    title: 'Fearless',
    albumID: 'fearless',
    displayTitle: 'FEARLESS',
  },
  speak_now: {
    title: 'Speak Now',
    albumID: 'speak_now',
  },
  red: {
    title: 'Red',
    albumID: 'red',
    displayTitle: 'RED',
  },
  nineteen89: {
    title: '1989',
    albumID: 'nineteen89',
    displayTitle: 'I989',
  },
  reputation: {
    title: 'Reputation',
    albumID: 'reputation',
  },
  lover: {
    title: 'Lover',
    albumID: 'lover',
  },
  folklore: {
    title: 'Folklore',
    displayTitle: 'folklore',
    albumID: 'folklore',
  },
  evermore: {
    title: 'Evermore',
    displayTitle: 'evermore',
    albumID: 'evermore',
  },
  midnights: {
    title: 'Midnights',
    albumID: 'midnights',
  },
  ttpd: {
    title: 'The Tortured Poets Department',
    albumID: 'ttpd',
    displayTitle: 'TTPD',
  },
  misc_songs: {
    title: 'Miscellaneous',
    albumID: 'misc_songs',
  },
  unreleased: {
    title: 'Unreleased Songs',
    albumID: 'unreleased',
  },
}
