
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';


const StyledLinkBlock = styled.div`
margin: 0 auto;
padding: 10px;
max-width: 80vw;
color: #7def6b;
font-family: Arial, Helvetica, sans-serif;
font-size: 20px;
background-color:  #e0218a;

& a:any-link  {
  color: #1feef2;
  font-weight: bold;

}

`

function UserAgent () {


  return <div>
    <h1>Hello World</h1>

    <StyledLinkBlock>
      <div>
        The user agent is {navigator.userAgent}

      </div>
    </StyledLinkBlock>

  </div>
} export default UserAgent