import { AlbumTitles, AllAlbums, entireCatalog, erasTourSetlist } from "../../Catalog/catalog";
import styled from "styled-components";
import { getSurpriseColor } from "./style";
import { AlbumID, ReleaseType, SongID } from "../../Catalog/types";
import { CombinedPlayInstancesBySongId, MashupsBySongID, SurpriseSong, SurpriseSongBySongID, getDate } from "./data";
import React, { useContext, useState } from "react";
import { IncludedSongsContext, SurpriseDisplayContext, WithMashupContext } from "./context";
import { GuitarEmoji, IncludedSongsCase, PianoEmoji, WithMashupsToggleTypes } from "./consts";
import { gaEvents } from "../../events";


function getDisplayTitle (songID: SongID) {
  const song = entireCatalog[songID]
  if (songID === "i_can_fix_him") return song.title

  return song.title.replace(/ \(.*\)/, '');
}


const StyledAlbumContainer = styled.div<{
  albumID: string,
}>`
  min-width: 250px;
  max-height: calc(100vh - 100px);

  padding: 60px 20px 60px 20px;
  overflow-y: auto;

  display: flex;
  align-items: center;

  text-transform: uppercase;

  ${({ albumID }) => {
    return `
      background-color: ${getSurpriseColor(albumID, 'backgroundColor')};
    `
  }}
`

const SmallerContainer = styled.div`
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledAlbumTitle = styled.h2<{
  albumID: AlbumID
}>`
  margin: 0 2px 5px 2px;
  padding-bottom: 5px;
  padding-right: 2px;

  text-align: center;
  font-size: 1.3em;
  // font-weight: 600;
  font-style: italic;

  position: relative;

  ${({ albumID }) => {
    return `
      color: ${getSurpriseColor(albumID, 'playedThisYear')};
      // border-bottom: 2px solid ${getSurpriseColor(albumID, 'erasSetlist')};
    `
  }}
`

const StyledSongContainer = styled.div<{
  surpriseStatus: string,
  albumID: string,
  toolTipLocation?: string,
  toolTipVisible?: boolean,
}>`
  margin-top: 3px;
  margin-bottom: 3px;
  text-align: center;
  font-size: 1.05em;
  font-weight: 550;

  position: relative;

  ${({ albumID, surpriseStatus: songType }) => {
    if (songType === "unplayed") {
      return `color: white;`
    } else if (songType === "erasSetlist") {
      return `color: ${getSurpriseColor(albumID, songType)};`
    } else {
      return `
      cursor: pointer;
      color: ${getSurpriseColor(albumID, songType)};
    `}
  }
  }}

  > div {
    ${({ toolTipVisible }) => {
    if (!toolTipVisible) {
      return `visibility: hidden;`
    }
  }}

    position: absolute;
    width: 200px;
    margin-left: -100px; -- half of width
    z-index: 10;

    padding: 10px;
    border-radius: 10px;

    background-color: #323232;
    font-size: 13px;
    opacity: 0.82;
    color: #fff;

    > div {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  > div::after {
    content: " ";
    position: absolute;
    
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
  }

  ${({ toolTipLocation }) => {
    if (toolTipLocation === "top") {
      // https://www.w3schools.com/css/css_tooltip.asp
      return `
        > div {
          bottom: 140%;
          left: 50%; 
        }
      
        > div::after {
          top: 100%;
          left: 50%;
          border-color: #323232 transparent transparent transparent;
        }`
    } else {
      return `
      > div {
        top: 140%;
        left: 50%; 
      }
    
      > div::after {
        bottom: 100%;
        left: 50%;
        border-color: transparent transparent #323232 transparent;
      }`
    }
  }}
`

const StyledSongDivider = styled.div<{
  albumID: string,
}>`
  width: calc(100% - 80px);
  margin-top: 5px;
  margin-bottom: 5px;

  ${({ albumID }) => {
    return `border: 1px solid ${getSurpriseColor(albumID, 'erasSetlist')};`
  }}
`

function SongContainer ({
  songID,
  albumID,
  playInstances,
  mashupInstances,
  surpriseStatus,
  displayEmoji,
}: {
  songID: string,
  albumID: string,
  playInstances: any[],
  mashupInstances: any[],
  surpriseStatus: string,
  displayEmoji: boolean,
}) {
  const [mouseInToolTip, updateMouseInTooltip] = useState(false)
  const [itemClicked, updateItemClicked] = useState(false)
  const [recentClick, updateRecentClick] = useState(false)

  function updateClickState () {
    // The popover should immediately go away if someone clicks out of a popover
    // We don't need for someone to mouse out.
    if (itemClicked) {
      updateRecentClick(true)
      setTimeout(() => updateRecentClick(false), 1500)

      gaEvents.logSurpriseToolTipClicked(songID)
    }
    updateItemClicked(!itemClicked)
  }

  const displayTitle = getDisplayTitle(songID)

  const withMashupSetting = useContext(WithMashupContext) as WithMashupsToggleTypes
  const displayMashups = withMashupSetting === 'withMashups'

  if (playInstances.length === 0) {
    return <StyledSongContainer
      albumID={albumID}
      surpriseStatus={surpriseStatus}
    >
      {displayTitle} {displayEmoji ? '💔' : ''}
    </StyledSongContainer>
  }

  const dateOrder = playInstances.sort((a, b) => getDate(a.date).getTime() - getDate(b.date).getTime())

  return <StyledSongContainer
    albumID={albumID}
    surpriseStatus={surpriseStatus}
    toolTipLocation="top"
    toolTipVisible={(mouseInToolTip || itemClicked) && !recentClick}
  >
    <span
      onMouseEnter={() => { updateMouseInTooltip(true) }}
      onMouseLeave={() => { updateMouseInTooltip(false) }}
      onMouseDown={() => { updateClickState() }}
    >{displayTitle}</span>

    <div onMouseDown={() => { updateClickState() }}>
      {
        dateOrder.map((instance: SurpriseSong, idx: number) => {
          let emoji = ''
          if (instance.piano) emoji = PianoEmoji
          else if (instance.guitar) emoji = GuitarEmoji

          const includesMashups = !!instance.mashup

          return <div key={idx}>
            {emoji} {instance.city} N{instance.nightNumber}
            {
              (includesMashups && displayMashups) && <>
                <br />
                {getDisplayTitle(instance.songID)} x {getDisplayTitle(instance.mashup)}
                {instance.mashup2 && <> x {getDisplayTitle(instance.mashup2)}</>}
              </>
            }
            {
              instance.note && <><br />{instance.note}</>
            }
          </div>
        })
      }
    </div>
  </StyledSongContainer>
}

function figureOutState (sID: SongID, currentSetting: string, includedSongTypes: IncludedSongsCase[], withMashupSetting: WithMashupsToggleTypes) {
  let playInstances = CombinedPlayInstancesBySongId[sID] || []

  if (withMashupSetting === 'withoutMashups') {
    playInstances = SurpriseSongBySongID[sID] || []
  }

  const playInstances2024 = Object.values(playInstances).filter((s: { date: string; }) => s.date.slice(-2) === "24")
  const playInstances2023 = Object.values(playInstances).filter((s: { date: string; }) => s.date.slice(-2) === "23")

  let mashupInstances = MashupsBySongID[sID] || []
  let mashupInstances2024 = Object.values(mashupInstances).filter((s: { date: string; }) => s.date.slice(-2) === "24")
  let mashupInstances2023 = Object.values(mashupInstances).filter((s: { date: string; }) => s.date.slice(-2) === "23")

  // There are better ways to do this but this works technically :)
  if (withMashupSetting === 'withoutMashups') {
    mashupInstances = []
    mashupInstances2024 = []
    mashupInstances2023 = []
  }

  let surpriseStatus = 'unplayed'

  // special cases still allow thing to be overridden by a better color if one's available
  if (sID === 'no_body_no_crime' || sID === 'nothing_new' || sID === 'all_too_well' || erasTourSetlist.some(s => s === sID)) {
    surpriseStatus = 'erasSetlist'
  }

  switch (currentSetting) {
    case 'all':
      if (playInstances2024.length > 0 || mashupInstances2024.length > 0) {
        surpriseStatus = 'playedThisYear'
      } else if (playInstances2023.length > 0 || mashupInstances2023.length > 0) {
        surpriseStatus = 'playedLastYear'
      }
      break
    case 'just2023':
      playInstances = playInstances2023
      mashupInstances = mashupInstances2023
      surpriseStatus = (playInstances.length > 0 || mashupInstances.length > 0) ? 'playedThisYear' : surpriseStatus
      break
    case 'just2024':
      playInstances = playInstances2024
      mashupInstances = mashupInstances2024
      surpriseStatus = (playInstances.length > 0 || mashupInstances.length > 0) ? 'playedThisYear' : surpriseStatus
      break
  }


  let shouldSkip = false
  if (surpriseStatus === 'unplayed'
    && !includedSongTypes.includes('unplayedSongs')) {
    shouldSkip = true
  } else if (surpriseStatus === 'erasSetlist'
    && !includedSongTypes.includes('setListSongs')) {
    shouldSkip = true
  } else if (surpriseStatus === 'playedThisYear'
    && !includedSongTypes.includes('alreadyPlayedSongs')) {
    shouldSkip = true
  } else if (surpriseStatus === 'playedLastYear'
    && !includedSongTypes.includes('playedLastYearSongs')) {
    shouldSkip = true
  }

  return {
    songID: sID,
    surpriseStatus,
    playInstances,
    mashupInstances,
    shouldSkip,
    displayEmoji: surpriseStatus === 'unplayed' && currentSetting === 'all',
  }
}

function SurpriseAlbumContainer ({
  albumID,
}: {
  albumID: string,
}) {
  const currentSetting = useContext(SurpriseDisplayContext)
  const withMashupSetting = useContext(WithMashupContext) as WithMashupsToggleTypes
  const includedSongTypes = useContext(IncludedSongsContext)

  const album = AllAlbums[albumID]
  const songIDs = Object.keys(album)
    .filter(sID => album[sID].releaseType !== ReleaseType.remix)
    .filter(sID => sID !== 'all_too_well_ten')

  const songsToRender = songIDs
    .map(sID => figureOutState(sID, currentSetting, includedSongTypes, withMashupSetting))
    .filter(x => !x.shouldSkip)



  return (
    <StyledAlbumContainer albumID={albumID}>
      <SmallerContainer>
        <StyledAlbumTitle albumID={albumID}>{AlbumTitles[albumID].title}</StyledAlbumTitle>
        {
          songsToRender.map(function (song, idx) {
            const displayDivider = idx + 1 !== songsToRender.length
              && album[song.songID].releaseType !== album[songsToRender[idx + 1].songID].releaseType

            return [
              <SongContainer
                key={song.songID}
                albumID={albumID}
                songID={song.songID}
                playInstances={song.playInstances}
                mashupInstances={song.mashupInstances}
                surpriseStatus={song.surpriseStatus}
                displayEmoji={song.displayEmoji}
              />,
              displayDivider ? <StyledSongDivider key={song.songID + 'key'} albumID={albumID} /> : <React.Fragment key={song.songID + 'key'} />
            ]
          })
        }
      </SmallerContainer>
    </StyledAlbumContainer>
  )
}

export default SurpriseAlbumContainer