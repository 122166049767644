import * as React from 'react';
import { useState } from 'react';
import { ChangeEventHandler } from 'react';

import styled from 'styled-components';
import { HorizontalDots } from '../icons';
import { useIsMobile } from '../hooks/windowsize';

const NavBar = styled.div<{
    isMobile: boolean
}>`
  grid-area: navbar;

  position: sticky;
  top: 0;

  min-height: 38px;
  height: min-content;
  width: 100%;

  background: white;
  padding-bottom: 5px;
  padding: 5px;
  margin: 10px 0px;

  ${({ isMobile }) => {
        if (isMobile) {
            return `
            z-index: 10;
            transform: translate3d(0, 0, 0);
            `
        }
    }}

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 5px;
`


const StyledInput = styled.input`
    width: 130px;
    border-radius: 5px;
    border: 1px solid grey;
    font-weight: 400;
    height: 20px;
    font-size: 16px;
    line-height: 18px;
    padding: 4px;
    margin-right: 6px;

    :focus > {
        preventScroll: true
    }
`
const StyledScore = styled.div<{
    winner: boolean
}>`
    width 70px;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;

    ${({ winner }) => {
        if (winner) {
            return `color: green`
        }
    }}
`

const StyledButton = styled.button`
  background-color: rgba(51, 51, 51, 0.05);
  border-radius: 8px;
  border-width: 0;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  list-style: none;
  padding: 8px 10px;
  text-align: center;
  transition: all 200ms;
  
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
`

export function GameController({
    handleGiveUp,
    handleNewSong,
    handleAddHint,
    handleReset,
    handleRevealTitle,
    handleTextChange,
    text,
    totalGuessed,
    song,
    setRevealAlbum

}: {
    handleGiveUp: () => void,
    handleNewSong: () => void,
    handleReset: () => void,
    handleAddHint: () => void,
    handleRevealTitle: () => void,
    setRevealAlbum: React.Dispatch<React.SetStateAction<boolean>>
    handleTextChange: ChangeEventHandler<HTMLInputElement>,
    text: string
    totalGuessed: number
    song: string[]
}) {
    const [displayExtras, setDisplayExtra] = useState(false);
    const isMobile = useIsMobile()

    function onMenuClick() { setDisplayExtra(!displayExtras) }

    if (isMobile) {
        return (
            <NavBar isMobile={isMobile}>
                <StyledInput  autoFocus value={text} onChange={handleTextChange} placeholder='Enter lyrics here' />
                <StyledButton onClick={handleAddHint}>Hint</StyledButton>
                <StyledScore winner={totalGuessed === song.length}>&nbsp;{totalGuessed}/{song.length}</StyledScore>
                <HorizontalDots onClick={onMenuClick} />

                {displayExtras && <StyledButton onClick={() => setRevealAlbum(true)}>Reveal Album</StyledButton>}
                {displayExtras && <StyledButton onClick={handleRevealTitle}>Reveal Title</StyledButton>}
                {displayExtras && <StyledButton onClick={handleReset}>Reset</StyledButton>}
                {displayExtras && <StyledButton onClick={handleGiveUp}>Give up</StyledButton>}
                {displayExtras && <StyledButton onClick={handleNewSong}>New Song</StyledButton>}
                
            </NavBar>
        )
    } else {
        return (
            <NavBar isMobile={isMobile}>
                <StyledInput value={text} autoFocus onChange={handleTextChange} placeholder='Enter lyrics here' />
                <StyledScore winner={totalGuessed === song.length}>&nbsp;{totalGuessed}/{song.length}</StyledScore>
                <StyledButton onClick={handleAddHint}>Hint</StyledButton>
                <StyledButton onClick={() => setRevealAlbum(true)}>Reveal Album</StyledButton>
                <StyledButton onClick={handleRevealTitle}>Reveal Title</StyledButton>
                <StyledButton onClick={handleReset}>Reset Song</StyledButton>
                <StyledButton onClick={handleGiveUp}>Give up</StyledButton>
                <StyledButton onClick={handleNewSong}>New Song</StyledButton>
            </NavBar>
        )
    }


}