import * as React from 'react';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import Confetti from 'react-confetti';

// TODO relative imports
import { SettingsContainer } from './SettingsContainer/SettingsContainer';
import { AlbumTitles, defaultCheckedAlbums, entireCatalog } from './Catalog/catalog';
import { dictionary, homophoneDict, vocalizations } from './Catalog/homophones';
import { LyricContainer } from './SongContainer/SongContainer';
import { Status } from './SongContainer/Word';
import { sanitizeInput, getRandomInt } from './Util/utils';
import { GameController } from './GameControllerContainer/GameController';
import { gaEvents } from './events';
import { Footer } from './Footer';
import { debounceMS } from './Style';
import { SEOData } from './SEO/data';
import SEO from './SEO';
import { useCookies } from 'react-cookie';
import { useIsMobile } from './hooks/windowsize';
import SurveyModal from './SurveyModal';
import useModal from './useModal';

// This bad boy needs to go after all of the imports for some reason
const jsDamerauLev = require('damerau-levenshtein-js')

const MainBox = styled.div<{
  isMobile: boolean
}>`
${({ isMobile }) => {
    if (isMobile) {
      return ``
    } else {
      return `
      display: grid;
      grid-template-columns: 200px 1fr;
      grid-template-rows: 70px 1fr 20px;
      grid-template-areas:
        "sidebar navbar"
        "sidebar main"
        "footer footer";
      `
    }
  }}
`

export default function MainGamePage () {
  const [lyricInputDisabled, setLyricInputDisabled] = useState(false)

  const COOKIE_SUFFIX = '-cookie'
  const albumCookies = Object.keys(AlbumTitles).map(k => k + COOKIE_SUFFIX)
  const [cookies, setCookies] = useCookies(['leftToRight', 'randomOrder', 'revealVocalizations', 'survey', ...albumCookies])
  const initialLtRState = cookies.leftToRight === undefined ? false : cookies.leftToRight as boolean
  const initialRandomOrder = cookies.randomOrder === undefined ? true : cookies.randomOrder as boolean
  const initialRevealVocalizations = cookies.revealVocalizations === undefined ? false : cookies.revealVocalizations as boolean

  const anyAlbumCookiesSet = albumCookies.some(x => cookies[x] !== undefined && cookies[x])

  // TODO eras tour
  const intialElligibleSongs = Object.keys(entireCatalog)
    .filter(song_id => {
      if (anyAlbumCookiesSet) {
        const aID = entireCatalog[song_id].album
        // TODO can we getaway w/o the undefined checked? How does that behave as a bool?
        return cookies[aID + COOKIE_SUFFIX] !== undefined && cookies[aID + COOKIE_SUFFIX]
      } else {
        return defaultCheckedAlbums.includes(entireCatalog[song_id].album);
      }
    })
  const initialSong = intialElligibleSongs[getRandomInt(intialElligibleSongs.length)]

  const [leftToRight, setLeftToRight] = useState(initialLtRState)
  const [randomOrder, setRandomOrder] = useState(initialRandomOrder)
  const [revealVocalizationsSetting, setRevealVocalizations] = useState(initialRevealVocalizations)

  const [elligibleSongs, setElligibleSongs] = useState(intialElligibleSongs)

  const [trackName, setTrackName] = useState(initialSong)
  const [seenTrackNames, setSeenTrackNames] = useState([initialSong])

  const [text, setText] = useState('');
  const [seenWords, setSeenWords] = useState([])

  const [possibleNextLetters, setPossibleNextLetters] = useState([''])
  const [guessedWords, setGuessedWords] = useState<{ [id: string]: Status }>({})
  const [uniqueWords, setUniqueWords] = useState([''])
  const [song, setSong] = useState([''])
  const [displayTitle, setDisplayTitle] = useState('')
  // For songs like "ronan", "imgonnagetyouback", and "loml"
  const [effectiveTitle, setEffectiveTitle] = useState('')
  const [albumTitle, setAlbumTitle] = useState('')
  const [revealTitle, setRevealTitle] = useState(false)
  const [revealAlbum, setRevealAlbum] = useState(false)
  const [vocalizationsRevealed, setVocalizationsRevealed] = useState(false)
  const [guessedTitleWords, setGuessedTitleWords] = useState<{ [id: string]: Status }>({})

  const { isShowing, toggle } = useModal();

  const isMobile = useIsMobile()

  useEffect(() => {
    const handleSpecialKeys = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setText('')
        event.preventDefault()
      } else if (event.key === "Enter") {
        // TODO error and warn state
        setText('')
      }
    };
    window.addEventListener('keydown', handleSpecialKeys);

    return () => {
      window.removeEventListener('keydown', handleSpecialKeys);
    };
  }, []);


  useEffect(setupSongInitialState, [trackName])

  useEffect(() => {
    // When user toggles off of an album that the first song is set to
    if (seenWords.length === 0
      && !elligibleSongs.includes(trackName)
      && elligibleSongs.length > 0
    ) {
      shuffleNewSong()
    }
  })

  function updateUserSetting (setting: string, value: boolean) {
    if (setting === "leftToRight") {
      setLeftToRight(value)
      setCookies('leftToRight', value)
    } else if (setting === "randomOrder") {
      setRandomOrder(value)
      setCookies('randomOrder', value)
    } else if (setting === 'revealVocalizations') {
      setRevealVocalizations(value)
      setCookies('revealVocalizations', value)
    } else {
      console.log('cannont handle setting ', setting)
    }
  }

  function setupSongInitialState () {
    setText('')
    setSeenWords([])
    setRevealTitle(false)
    setRevealAlbum(false)
    setVocalizationsRevealed(false)

    const albumID = entireCatalog[trackName].album
    setAlbumTitle(AlbumTitles[albumID].title)

    const lyrics = entireCatalog[trackName].lyrics
    const s = lyrics.split(" ").filter(w => w.length > 0).filter(w => w !== "NEWLINE")
    setSong(s)

    let initalGuessedState: { [id: string]: Status; } = {}
    const uws = s.map(word => sanitizeInput(word))
    uws.forEach(word => initalGuessedState[word] = Status.unGuessed)

    setGuessedWords(initalGuessedState)
    setUniqueWords(uws)

    const newTitle = entireCatalog[trackName].title
    let effectiveTitle = newTitle
    setDisplayTitle(newTitle)
    if (entireCatalog[trackName].effectiveTitle) {
      effectiveTitle = entireCatalog[trackName].effectiveTitle
    }
    setEffectiveTitle(effectiveTitle)

    let initalTitleGuessedState: { [id: string]: Status; } = {}
    const utws = effectiveTitle.replace(/ *\([^)]*\) */g, "").split(" ").map(word => sanitizeInput(word)).filter(w => w.length > 0)
    utws.forEach(word => initalTitleGuessedState[word] = Status.unGuessed)
    setGuessedTitleWords(initalTitleGuessedState)
  }

  const totalRevealed = uniqueWords.filter(word => guessedWords[word]).length
  const totalGuessed = uniqueWords.filter(word => guessedWords[word] === Status.guessed).length
  const winner = totalRevealed === uniqueWords.length

  useEffect(() => {
    if (winner) {
      const numWordsRemaining = Object.values(guessedWords).filter(s => s === Status.gaveUp).length
      gaEvents.logSongCompleted(trackName, numWordsRemaining)

      if (numWordsRemaining === 0) {
        gaEvents.logSongWon(trackName)
      } else {
        gaEvents.logSongGivenUp(trackName, numWordsRemaining)
      }

    }
  }, [winner, trackName, guessedWords])

  useEffect(() => {
    if (seenWords.length === 1) {
      gaEvents.logSongStarted(trackName)
    }
  }, [seenWords, trackName])

  const unguessedTitleWords = effectiveTitle
    .split(" ")
    .map(word => sanitizeInput(word))
    .filter(word => guessedTitleWords[word] === Status.unGuessed)
    .filter(word => guessedWords[word] !== undefined)

  const titleGuessed = unguessedTitleWords.length === 0

  function markWordAsSeenWrapper (word: string) {
    gaEvents.logIndividualWordRevealed(trackName, word)

    word = sanitizeInput(word)
    markWordAsSeen(word)
    homophoneDict[word]?.forEach((word: string) => markWordAsSeen(word))
  }

  function markWordAsSeen (guess: string) {
    guess = sanitizeInput(guess)

    if (!uniqueWords.includes(guess) || seenWords.includes(guess)) {
      return []
    }

    const newSeen = seenWords.concat(guess)
    let newGuessedWords = guessedWords
    newGuessedWords[guess] = Status.guessed
    setGuessedWords(newGuessedWords)
    setSeenWords(newSeen)


    const nextLetters = song.filter((w, i) => {
      if (i === 0) { return false }

      const currentWord = sanitizeInput(w)
      const precedingWord = sanitizeInput(song[i - 1])

      return precedingWord === guess && !newSeen.includes(currentWord)
    }).map(w => sanitizeInput(w)[0])

    if (Object.keys(guessedTitleWords).includes(guess)) {
      let newGuessedTitle = guessedTitleWords
      newGuessedTitle[guess] = Status.guessed
      setGuessedTitleWords(newGuessedTitle)
    }

    return nextLetters
  }

  useEffect(() => {
    if (titleGuessed) {
      gaEvents.logSongTitleFullyGuessed(trackName)
    }
  }, [titleGuessed, trackName])


  function possiblyRevealVocalizations (forced = false) {
    if (vocalizationsRevealed || !revealVocalizationsSetting) { return }

    if (titleGuessed || revealTitle || forced) {
      vocalizations.forEach(w => markWordAsSeenWrapper(w))
      setVocalizationsRevealed(true)
    }
  }

  function findMatch (guess: string) {
    function isExactMatch (g: string) {
      return uniqueWords.includes(g) && !seenWords.includes(g)
    }

    function findNearMatch (guess: string) {
      // allowing the guess length to be 4 is a little too permissive
      // If the user types in a valid word (like "clear") I dont want it to match to another (like "clean")
      if (guess.length <= 4 || dictionary.has(guess)) {
        return ''
      }

      // In haunted when you type 'breat', 
      // It fuzzy matches 'break' but is also the beginning of 'breathe'
      // The first few letters of a word are a fuzzy match with another word.
      let unseenWords = uniqueWords.filter(w => !seenWords.includes(w))
      const exceptedStems = new Set(unseenWords.filter(w => w.length >= guess.length).map(w => w.slice(0, guess.length)))
      if (exceptedStems.has(guess)) { return '' }

      let bestMatch = ''
      uniqueWords
        .filter(w => !seenWords.includes(w))
        .filter(w => w.length > 3)
        .filter(w => w !== guess)
        // If the user is the last char away from typing it correctly, skip 
        .filter(w => w.slice(0, -1) !== guess)
        // Only want to fuzz words if they're close in length
        // TODO how fuzzy can this be?
        .filter(w => guess.length >= w.length - 1)
        .forEach(w => {
          const levDistance = jsDamerauLev.distance(w, guess)
          if (levDistance === -1) {
            return // signifies error
          }

          // TODO how do we feel about edit distance of 2 for longer words?
          if (levDistance <= 1 || (levDistance <= 2 && w.length > 7)) {
            if (bestMatch !== '' && bestMatch !== w) {
              gaEvents.logPossibleFuzzyCollision(guess, bestMatch, w)

              console.log('collision: ', levDistance, ' Guess: ', guess, " w1: ", bestMatch, " w2: ", w)
            }
            bestMatch = w
          }
        })

      return bestMatch
    }

    if (isExactMatch(guess) || homophoneDict[guess]?.some((w: string) => isExactMatch(w))) {
      return guess
    } else {
      const match = findNearMatch(guess)
      if (match !== '') {
        // console.log('fuzzymatch: ', guess, match)
        gaEvents.logFuzzyMatch(guess, match)
      }
      return match
    }

  }

  function handleTextChange (e: React.ChangeEvent<HTMLInputElement>) {
    // Debounce user input when someone types "and" but "a" gets matched
    if (lyricInputDisabled) {
      if (possibleNextLetters.includes(e.target.value)) {
        setLyricInputDisabled(false)
      } else {
        setText('')
        return
      }
    }

    setText(e.target.value);
    const guess = sanitizeInput(e.target.value)
    const match = findMatch(guess)
    if (match === '') { return }

    setPossibleNextLetters([])
    let nextLetters = markWordAsSeen(match)
    homophoneDict[match]?.forEach((word: string) => nextLetters.concat(markWordAsSeen(word)))
    if (!['i', 'a'].includes(match)) {
      nextLetters.concat(markWordAsSeen(match + 's'))
    }
    if (!['i', 'the', 'a'].includes(match)) {
      // These words are often guessed at the beginning
      // It's unlikely that someone will be typing a string of lyrics with these words
      setPossibleNextLetters(nextLetters)
    }


    // TODO is is possible to put a space to the keyboard to fix that iphone issue
    setText('')
    setLyricInputDisabled(true)
    setTimeout(() => setLyricInputDisabled(false), debounceMS)

    possiblyRevealVocalizations(false)


    // TODO can I have this only run on localhost?
    const unGuessedLogging = Object.entries(guessedWords)
      .filter(([_, v]) => v === Status.unGuessed)
      .map(list => list[0])

    if (unGuessedLogging.length % 5 === 0) {
      console.log(unGuessedLogging)
    }

  }

  function handleAddHint () {
    const unGuessed = Object.entries(guessedWords)
      .filter(([_, v]) => v === Status.unGuessed)
      .map(list => list[0])

    const hintedWord = unGuessed[getRandomInt(unGuessed.length)]
    // TODO this should turn blue :)
    markWordAsSeen(hintedWord)
  }

  function handleReset () {
    setupSongInitialState()
  }

  function handleRevealTitle () {
    setRevealTitle(true)
    possiblyRevealVocalizations(true)
  }

  function handleGiveUp () {
    let newGuessedWords = guessedWords
    for (const word in newGuessedWords) {
      if (newGuessedWords[word] !== Status.guessed) {
        newGuessedWords[word] = Status.gaveUp
      }
    }
    setRevealTitle(true)
    setGuessedWords(newGuessedWords)
    setText('')
    setSeenWords([])
  }

  function shuffleNewSong () {
    // this is basically handle new song but when the user does some shit at the beginning
    let possibleSongs = elligibleSongs.filter(song => !seenTrackNames.includes(song));

    const nextSongIndex = randomOrder ? getRandomInt(possibleSongs.length) : 0
    const nextSong = possibleSongs[nextSongIndex]

    setTrackName(nextSong)
  }

  function displayModalIfElligible () {
    if (cookies.survey || isMobile) {
      return
    }

    // // Not a bad hack :shrug: best that I can do w/o IP logging
    // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    // gaEvents.logTimeZoneType(timezone)
    // if (!timezone.includes("America")) {
    //   return
    // }

    const today = new Date();
    const month = today.getMonth() + 1
    const date = today.getDate()
    const year = today.getFullYear()

    console.log(today, month, date, year)


    // I want my survey to show for two weeks
    // I started my survey on 11/24/2024 and want it to run for 2 weeks
    if (year > 2024) { return }
    if ((month === 12 && date <= 8) || month === 11) {
      setCookies('survey', 'seen');
      gaEvents.logSurveyModalDisplayed();
      toggle()
    }
  }

  function handleNewSong () {
    displayModalIfElligible()
    let possibleSongs = elligibleSongs.filter(song => !seenTrackNames.includes(song));

    let newSeenTrackNames = seenTrackNames.slice()

    // When the user has played through all of the possible songs
    if (possibleSongs.length === 0) {
      newSeenTrackNames = []
      setSeenTrackNames([])

      possibleSongs = elligibleSongs.filter(song => !seenTrackNames.includes(song));

      // in the case when the user has nothing selected
      // go back to the initial state
      if (possibleSongs.length === 0) {
        possibleSongs = intialElligibleSongs.slice()
      }
    }

    const nextSongIndex = randomOrder ? getRandomInt(possibleSongs.length) : 0
    const nextSong = possibleSongs[nextSongIndex]

    newSeenTrackNames.unshift(nextSong)

    setSeenTrackNames(newSeenTrackNames)
    setTrackName(nextSong)
  }

  return (
    <MainBox isMobile={isMobile}>


      <SEO seoObject={SEOData.songGuesser} />

      {winner && <Confetti />}
      <SettingsContainer
        updateUserSetting={updateUserSetting}
        setElligibleSongs={setElligibleSongs}
        revealVocalizations={revealVocalizationsSetting}
        leftToRight={leftToRight}
        randomOrder={randomOrder}
      />
      <GameController
        handleGiveUp={handleGiveUp}
        handleNewSong={handleNewSong}
        handleTextChange={handleTextChange}
        handleReset={handleReset}
        handleAddHint={handleAddHint}
        handleRevealTitle={handleRevealTitle}
        text={text}
        totalGuessed={totalGuessed}
        song={song}
        setRevealAlbum={setRevealAlbum}
      />
      <SurveyModal isShowing={isShowing} hide={toggle} />
      <LyricContainer
        title={displayTitle}
        showTitle={revealTitle || titleGuessed}
        lyrics={song}
        guessedWords={guessedWords}
        leftToRight={leftToRight}
        revealAlbum={revealAlbum}
        albumTitle={albumTitle}
        markWordAsSeen={markWordAsSeenWrapper}
      />
      <Footer />
    </MainBox >
  );
}

