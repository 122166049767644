import { useState } from "react";
import styled from "styled-components";


const StyledLink = styled.a`
  // display: flex;
  // flex-direction: column;
  overflow: hidden;
  text-color: blue;

  // justify-content: center;
  // align-items: center;
`

const StyledDiv = styled.span`
  // display: flex;
  // flex-direction: column;

  // justify-contents: center;
  cursor: pointer;
`


export function CopyLink () {
  const [base, setBase] = useState<boolean>(true);

  const link = 'https://forms.gle/kEBdk1pQqB7wPYGVA'

  return (
    <StyledLink
      href={link}
      onClick={async (e) => {
        e.preventDefault();
        if (navigator) {
          await navigator.clipboard.writeText(link);
          setBase(false);
          setTimeout(() => setBase(true), 1000);
        }
      }}
    >
      <StyledDiv>
        {
          base && <div>Copy Link</div>
        }
        {
          !base && <div>Copied!</div>
        }
      </StyledDiv>
    </StyledLink>
  );
}