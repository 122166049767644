
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';


const StyledLinkBlock = styled.div`
margin: 0 auto;
padding: 4rem;
width: 48rem;
color: #7def6b;
font-family: Arial, Helvetica, sans-serif;
font-size: 20px;
background-color:  #e0218a;

& a:any-link  {
  color: #1feef2;
  font-weight: bold;

}

`

function RedirectURL () {

  const location = useLocation()

  const baseForm = 'https://rehearsal.rally.win/join/hello'

  const fullLink = `${baseForm}/${location.search}`

  return <div>
    <h1>Hello World</h1>

    <StyledLinkBlock>

      {!location.search && <h2>There are no search params? Did you follow the right link?!?!</h2>}




      <div>
        Here is the whole link <a href={fullLink} target='_blank' rel="noreferrer">{fullLink}</a>

      </div>
    </StyledLinkBlock>

  </div>
} export default RedirectURL