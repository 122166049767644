import { Fragment, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { IncludedSongsContext, SurpriseDisplayContext, WithMashupContext } from "./context"
import { gaEvents } from "../../events"
import { IncludedSongsCase, SurpriseToggleDisplayStrings, SurpriseToggleTypesCase, WithMashupsToggleTypes } from "./consts"
import { getScreenWidth } from "../../Util/utils"

const songInclusionsDisplay = [
  {
    'id': 'setListSongs',
    'displayString': 'Setlist Songs'
  },
  {
    'id': 'unplayedSongs',
    'displayString': 'Songs never played'
  },
  {
    'id': 'alreadyPlayedSongs',
    // TODO how to make this thing clearer
    'displayString': 'Songs already played'
  },
  {
    'id': 'playedLastYearSongs',
    'displayString': 'Songs played last year'
  },
]

const surpriseSettings = ['just2023', 'just2024', 'all'] as SurpriseToggleTypesCase[]
const withMashupSettings = ['withMashups', 'withoutMashups'] as WithMashupsToggleTypes[]

// TODO Do I need to isMobile thing?
const SettingContainer = styled.div<{
  isMobile: boolean
}>`
  padding-left: ${(getScreenWidth() - 400) / 2}px;
  padding-right: ${(getScreenWidth() - 400) / 2}px;
`

const CloseModalDiv = styled.div`
  text-align: right;
  padding-bottom: 5px;
  text-style: bold;
  font-weight: 800;

  &:after {
    content: 'X';
    right: -10px;
    top: 0px;
  }
`


export function SurpriseSettingsContainer ({
  updateSurpriseSongSetting,
  updateWithMashupsSetting,
  toggleSettingsBar,
  isMobile,
  setExcludedSongs,
}: {
  updateSurpriseSongSetting: (newSetting: SurpriseToggleTypesCase) => void
  updateWithMashupsSetting: (newSetting: WithMashupsToggleTypes) => void
  toggleSettingsBar: () => void
  isMobile: boolean
  setExcludedSongs: React.Dispatch<React.SetStateAction<IncludedSongsCase[]>>
}) {
  const currentSurpriseSelection = useContext(SurpriseDisplayContext)
  const includedSongTypes = useContext(IncludedSongsContext)
  const mashupSetting = useContext(WithMashupContext)

  // TODO Do I need this?
  const [someVar, setSomeVar] = useState(null);
  const renderData = () => {
    setSomeVar(!someVar);
  }

  useEffect(() => { console.log(mashupSetting) }, [mashupSetting])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      gaEvents.logIncludedSurpriseSongsUpdated(includedSongTypes, currentSurpriseSelection)
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [currentSurpriseSelection, includedSongTypes]);

  function updateIncludedSongs (value: string) {
    if (value === '') {
      return
    }

    let newList = includedSongTypes.map(x => x)

    if (newList.includes(value)) {
      const index = newList.indexOf(value)
      newList.splice(index, 1)
    } else {
      newList.push(value)
    }

    setExcludedSongs(newList)
    renderData()
  }


  return <SettingContainer isMobile={isMobile}>
    {
      !isMobile && <CloseModalDiv onClick={() => toggleSettingsBar()} />
    }
    <link href="https://cdn.jsdelivr.net/css-toggle-switch/latest/toggle-switch.css" rel="stylesheet" />

    <div className="switch-toggle switch-3 switch-candy switch-candy-blue">

      {surpriseSettings.map(setting => {
        return <Fragment key={setting}>
          <input
            value={setting}
            id={setting}
            readOnly
            name="surprise"
            type="radio"
            disabled={currentSurpriseSelection === setting}
            checked={currentSurpriseSelection === setting}
            onClick={() => updateSurpriseSongSetting(setting)}
          />
          <label htmlFor={setting} className="disabled">{SurpriseToggleDisplayStrings[setting]}</label>
        </Fragment>
      })
      }

      {/* TODO translate this CSS into something local to jhuz it up */}
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
      <a></a>
    </div>
    <br />
    <div className="switch-toggle switch-2 switch-candy switch-candy-blue">
      {withMashupSettings.map(setting => {
        return <Fragment key={setting}>
          <input
            value={setting}
            id={setting}
            readOnly
            name="mashup"
            type="radio"
            disabled={mashupSetting === setting}
            checked={mashupSetting === setting}
            onClick={() => updateWithMashupsSetting(setting)}
          />
          <label htmlFor={setting} className="disabled">{setting === "withMashups" ? 'With Mashups' : 'Without Mashups'}</label>
        </Fragment>
      })
      }

      {/* TODO translate this CSS into something local to jhuz it up */}
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
      <a></a>
    </div>
    <br />
    <div>
      {/* TODO figure out how to handle the "last year" songs in a way that makes sense */}
      {songInclusionsDisplay.map(item => {
        const settingDisabled = item.id === 'playedLastYearSongs' && currentSurpriseSelection !== 'all'

        return <div
          key={item.id}
          onClick={() => settingDisabled ? updateIncludedSongs('') : updateIncludedSongs(item.id)}
        >
          <input
            readOnly
            type="checkbox"
            name={item.id + 'control'}
            value={item.id}
            checked={includedSongTypes.includes(item.id)}
            disabled={settingDisabled}
          />
          <label htmlFor={item.id} >{item.displayString}</label>
          <br />
        </div>
      })
      }
    </div>
  </SettingContainer>
}


