import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import MainGamePage from './MainGamePage'
import Contact from './pages/contact'
import FAQPage from './pages/faq'
import ChangeLog from './pages/change_log'
import reportWebVitals from './reportWebVitals'
import './index.css'

import './design/fonts/evermore/imfepiit28p-webfont.woff'
import './design/fonts/lover/pinyonscript-regular-webfont.woff'
import './design/fonts/reputation/chomsky-webfont.woff2'
import './design/fonts/eras_tour/pistilli-roman-webfont.woff'
import PrettySurpriseSongs from './pages/surprise_songs'
import { PageDestination } from './Footer/data'
import Clowning from './pages/clowning'
import NormailzedGraph from './pages/graph'
import RedirectURL from './pages/rally'
import UserAgent from './pages/user_agent'
import EmailCopy from './pages/email'

const root = ReactDOM.createRoot(document.getElementById('root'))
ReactGA.initialize('G-7WK00R83VK')

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/'>
            <Route index element={<MainGamePage />} />
            <Route path='contact' element={<Contact />} />
            <Route path={PageDestination.changeLog} element={<ChangeLog />} />
            <Route
              path={PageDestination.surpriseSongs}
              element={<PrettySurpriseSongs />}
            />
            <Route path={PageDestination.faq} element={<FAQPage />} />
            <Route path='/clowning' element={<Clowning />}></Route>
            <Route path='/graphs' element={<NormailzedGraph />}></Route>
            <Route path='/rally' element={<RedirectURL />}></Route>
            <Route path='/user-agent' element={<UserAgent />} />
            <Route path='/rally-email' element={<EmailCopy />} />
            <Route path='*' element={<MainGamePage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  )
}
root.render(
  <>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </>
)

reportWebVitals()
