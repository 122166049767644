import { Helmet } from 'react-helmet-async';
import { SeoEntry } from './data';


export default function SEO ({
  seoObject
}: {
  seoObject: SeoEntry
}) {
  return (
    <Helmet>
      { /* Standard metadata tags */}
      <title>{seoObject.title}</title>
      <meta name='description' content={seoObject.description} />
      {seoObject.keywords && <meta name='keywords' content={seoObject.keywords} />}

      { /* Facebook tags */}
      <meta property="og:type" content='website' />
      <meta property="og:title" content={seoObject.title} />
      <meta property="og:title" content='TSSongGuesser' />
      <meta property="og:locale" content="en_US" />
      <meta property="og:description" content={seoObject.description} />

      { /* Twitter tags */}
      <meta name="twitter:card" content='website' />
      <meta name="twitter:creator" content='@TessHuelskamp' />
      <meta name="twitter:title" content={seoObject.title} />
      <meta name="twitter:description" content={seoObject.description} />
    </Helmet>
  )
}