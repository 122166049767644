import styled from 'styled-components';
import { CopyLink } from '../SongContainer/CopyLink';

export const StyledModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledModalCloseButton = styled.div`
  font-size: 2rem;
  font-weight: 500;
  line-height: 1;
  color: #000;
  opacity: 1.0;
  cursor: pointer;
  border: none;
`

export const StyledButton = styled.button`
  font-size: .9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: .3rem 1rem;
  margin-left: .5rem;
  size: 80px;
}
`

export const StyledNewModal = styled.div`
  padding: 4px;
  
  width: 80%; 
  // height: vh;
  border: 1px solid black;


  z-index: 100;
  background: white;
  background-color: #fefefe;
  position: relative;
  
  border-radius: 3px;
  max-width: 500px;
  padding: 2rem;

  font-size: 1.25rem;

  // not my best work but it's fine
  grid-area: main;

  margin: auto;
  margin-top: 80px;
`
const SurveyModal = (
  {
    isShowing,
    hide
  }:
    {
      isShowing: boolean,
      hide: () => void
    }
) => {
  if (!isShowing) {
    return null
  }




  return <StyledNewModal >
    <StyledModalHeader>
      <StyledButton data-dismiss="modal" aria-label="Close" onClick={hide}>
        <span aria-hidden="true">&times;</span>
      </StyledButton>
    </StyledModalHeader>
    <h2>
      Hello! Mind taking a quick survey?
    </h2>
    <p>
      I have some free time coming up and am curious to hear everyone's thoughts on what they want me to do with this site!
    </p>
    
    <p> I put together a quick <a href="https://forms.gle/kEBdk1pQqB7wPYGVA">survey</a> that should take less than 5 minutes to take.</p>

    <div>You can copy the link over here: <CopyLink /></div>

    <p>Thanks! -Tess </p>
    <StyledModalHeader onClick={hide}>
      <p >
        Click here to exit
      </p>
    </StyledModalHeader>
  </StyledNewModal>
}


export default SurveyModal;